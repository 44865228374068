<template>
  <div class="flex-column" v-loading="loading">
    <sticky :className="'sub-navbar'">
      <div class="filter-container">
        <base-search :searchList="$jczlQyglSearch" :listQuery='listQuery' v-on:search='handleFilter' v-on:btn-event="onBtnClicked" style="display: inline-block;"></base-search>
      </div>
    </sticky>
    <div class="app-container flex-item">
      <!-- 拖拽div -->
      <div class="dragBox" ref="dragBox">
        <div class="dragBox_left">
          <!--左侧div内容-->
          <el-card shadow="never" class="body-small" style="height: 100%;overflow:auto;">
            <basic-tree :isShowCheck="true" @handleNodeClick="handleNodeClick" @getCheckedKeys="getCheckedKeys" @nodeClickOne="nodeClickOne"></basic-tree >
          </el-card>
        </div>
        <div class="dragBox_resize" title="收缩侧边栏">
          ⋮
        </div>
        <div class="dragBox_mid">
          <!--右侧div内容-->
          <div class="bg-white" style="height: 100%;" id="table_box_height">
            <basic-table :tableHeight="tableHeight" :tableHeaderList="firstHeaderList" :tableData="tableData" :listQuery="listQuery" @pagination="handleCurrentChange" @tableRowClick="tableRowClick" :tableTotal="tableTotal" @handleSelectionChange="handleSelectionChange" :hasSelection="true"></basic-table >
          </div>
        </div>
      </div>
      <!-- 拖拽div -->
    </div>


    <!-- 添加弹窗 -->
    <general-form :FormDialog="FormDialog" :FormList="FormList" :title="addTitle" :FormData="addform"  @cancelDialog="cancelDialog" @confirmDialog="confirmDialog"></general-form>

    <!-- 编辑弹窗 -->
    <general-form :FormDialog="FormDialogEdit" :FormList="FormList" :title="EditTitle" :FormData="modifyForm" @cancelDialog="cancelDialogEdit" @confirmDialog="confirmDialogEdit" :areaChangeChoose="areaChangeChoose"></general-form>

    <!-- 区域调整 -->
    <general-form :FormDialog="areaChangeDialog" :FormList="areaChangeList" :title="'区域调整'" :FormData="areaChangeForm" @cancelDialog="cancelAreaChange" @confirmDialog="confirmAreaChange" :isSpanAll="true" :areaChangeChoose="areaChangeChoose" :isAreaTree="true"></general-form>

    <!-- 分配用户 -->
    <distributionUser :userDialog="userDialog" :list="usersList" @handleDistributionUser="handleDistributionUser" @cancelUserDialog="cancelUserDialog"></distributionUser>
    
  </div>

</template>

<script>
  import generalForm from '@/components/generalForm'//通用添加弹窗组件
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as users from '@/api/users'//获取用户接口
  
  import '@riophae/vue-treeselect/dist/vue-treeselect.css'
  import waves from '@/directive/waves' // 水波纹指令
  import Sticky from '@/components/Sticky'
  import elDragDialog from '@/directive/el-dragDialog'
  export default {
    name: 'hdybqygl',
    components: {
      Sticky,
      generalForm
    },
    directives: {
      waves,
      elDragDialog
    },
    data() {
      return {
        loading:false,//遮罩
        listQuery: { // 查询条件
          page: 1,
          limit: 50,
          TreeIds:[],
          isMountDevice:'0',
          isMountMap:'0',
          areaName: undefined
        },
        orgs: [], // 用户可访问到的组织列表
        tableData:[],
        tableHeight:500,//表格高度
        CheckedKey:[],//选中数据的id集合
        FormDialog:false,//添加弹窗
        addform:{},//添加表单
        modifyForm:{},//编辑表单
        FormList:{//表格列
          column:[
            // {columnKey:'areaId',columnDescription:'区域ID',columnType:'text',prop:'areaId',width:'120',placeholder:"区域ID",},
            {columnKey:'areaName',columnDescription:'区域名称',columnType:'text',prop:'areaName',width:'120',placeholder:"区域名称",},
            {columnKey:'areaNum',columnDescription:'区域编号',columnType:'text',prop:'areaNum',width:'120',placeholder:"区域编号",},
            {columnKey:'geographicalPosition',columnDescription:'地理位置',columnType:'text',prop:'geographicalPosition',width:'120',placeholder:"地理位置",isChooseCoordinates:true,},
            {columnKey:'detailedAddress',columnDescription:'详细地址',columnType:'text',prop:'detailedAddress',width:'120',placeholder:"详细地址",isMap:true,},
            {
              columnKey:'isMountDevice',
              columnDescription:'是否挂载设备',
              columnType:'select',
              placeholder:"请选择是否挂载设备",
              prop:'isMountDevice',
              width:'120',
              seleceOption:[
                {label:'否',value:'0'},
                {label:'是',value:'1'},
              ],
              typeId:'Hyb_HaveDevice',//从全局数据字典里获取下拉数据
            },
            {
              columnKey:'isMountMap',
              columnDescription:'是否挂载地图',
              columnType:'select',
              placeholder:"请选择是否挂载地图",
              prop:'isMountMap',
              width:'120',
              seleceOption:[
                {label:'否',value:'0'},
                {label:'是',value:'1'},
              ],
              typeId:'Hyb_HaveMap',//从全局数据字典里获取下拉数据
            },
            {columnKey:'surfaceMountNum',columnDescription:'表挂载数量',columnType:'num',prop:'surfaceMountNum',width:'120',placeholder:"表挂载数量",},
            {
              columnKey:'fatherNode',
              columnDescription:'父节点',
              columnType:'selectArea',
              prop:'fatherNode',
              width:'120',
              placeholder:"父节点",
            },
            // {columnKey:'project',columnDescription:'项目',columnType:'text',prop:'project',width:'120',placeholder:"项目",},
            // {
            //   columnKey:'projectPerson',
            //   columnDescription:'项目负责人',
            //   columnType:'selectUser',
            //   prop:'projectPerson',
            //   width:'120',
            //   placeholder:"项目负责人",
            // },
            // {columnKey:'projectCode',columnDescription:'项目编号',columnType:'text',prop:'projectCode',width:'120',placeholder:"项目编号",},
            // {columnKey:'areaPersonNum',columnDescription:'区域负责人编号',columnType:'num',prop:'areaPersonNum',width:'120',placeholder:"区域负责人编号",},
            // {columnKey:'jurisdictionUser',columnDescription:'权限用户',columnType:'text',prop:'jurisdictionUser',width:'120',placeholder:"权限用户",},
          ],
          //校验
          rule:{
            areaName: [{ required: true, message: "区域名称不能为空", trigger: "blur" }, ],
            areaNum: [{ required: true, message: "区域编号不能为空", trigger: "blur" }, ],
            detailedAddress: [{ required: true, message: "详细地址不能为空", trigger: "blur" }, ],
          }
        },
        addTitle:'添加',//添加弹窗标题
        FormDialogEdit:false,//添加表弹窗
        EditTitle:'编辑',//添加弹窗标题
        firstHeaderList:[],// 主列表列定义
        tableTotal:0,//明细条数
        areaChangeDialog:false,//区域调整弹窗
        areaChangeList:{//区域调整表格列
          column:[
            {
              columnKey:'fatherAreaId',
              columnDescription:'区域',
              columnType:'selectAreaTree',
              prop:'fatherAreaId',
              width:'120',
              placeholder:"区域",
            },
          ],
          //校验
          rule:{
            area: [{ required: true, message: "区域不能为空", trigger: "blur" }, ],
          }
        },
        areaChangeForm:{},//区域调整表单
        mainMultipleSelection:[],//多选数据
        userDialog:false,//用户弹窗
        usersList:[],//所有用户
        areaChangeChoose:[],//区域调整当前选择数据,用于所有区域剔除自己
        
      }
    },
    watch: {

    },
    computed: {
      // orgsTree() {
      //   return this.$store.state.AllArea;
      // },
    },
    filters: {
      
    },
    created() {
      this.getList()
      this.getUserData()
    },
    mounted() {
      // 计算table高度
      this.$nextTick(()=>{
        let ele=document.getElementById("table_box_height");
        this.tableHeight=ele.offsetHeight-50
        window.addEventListener('resize', () => { 
          let ele=document.getElementById("table_box_height");
          this.tableHeight=ele.offsetHeight-50
          this.$forceUpdate()
        });
        // 计算table高度
      })
      this.$basicFun.dragControllerDiv();//拖拽div方法
    },
    methods: {
      // 节点点击
      handleNodeClick(data) {
        this.listQuery.TreeIds = data
      },
      // 添加节点选中数据
      getCheckedKeys(data){
        console.log(data)
        // this.CheckedKey = data
        // var s = this.CheckedKey.length
        // this.tableData = this.$store.state.tableDataSearch.slice(0,s)
      },
      //节点点击
      nodeClickOne(data){
        this.listQuery.TreeIds = []
        this.listQuery.TreeIds.push(data.id)
        this.getList()
      },
      // 按钮点击
      onBtnClicked: function(domId) {
        console.log('you click:' + domId)
        switch (domId) {
          case 'btnDel'://删除
            if(this.mainMultipleSelection.length == 1){
              this.handleDelete(this.mainMultipleSelection[0])
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'btnAdd'://添加
            this.FormDialog = true
            break
          case 'btnEdit'://编辑
            if(this.mainMultipleSelection.length == 1){
              this.areaChangeChoose = this.mainMultipleSelection
              this.modify(this.mainMultipleSelection[0])
            }else{
              this.$message({
                message: `请选择一条数据进行修改！`,
                type: 'error'
              })
            }
            break
          case 'areaChange'://区域调整
            if(this.mainMultipleSelection.length <= 0){
              this.$message({
                message: `请选择一条数据进行调整！`,
                type: 'error'
              })
            }else{
              this.areaChangeChoose = this.mainMultipleSelection
              this.areaChangeDialog = true
            }
            break
          case 'distributionUser'://分配用户
            if(this.listQuery.TreeIds.length <= 0){
              this.$message({
                message: `请选择左侧树形数据进行分配！`,
                type: 'error'
              })
            }else{
              this.userDialog = true
            }
            // if(this.mainMultipleSelection.length <= 0){
            //   this.$message({
            //     message: `请选择一条数据进行调整！`,
            //     type: 'error'
            //   })
            // }else{
            //   this.userDialog = true
            // }
            break
          default:
            break
        }
      },
      // 获取用户数据
      getUserData(){
        var data={
          page: 1,
          limit: 50
        }
        users.getList(data).then(response => {
          if(response.code == 200){
            this.usersList = response.data
          }
        })
      },
      // 获取数据
      getList() {
        this.loading = true
        basicDataApi.qyglGetList(this.listQuery).then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders.filter(u => u.browsable && u.isList)
            this.tableData = response.data
            this.tableTotal = response.count
            this.loading = false
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
            this.loading = false
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
          this.loading = false
        })
        // this.tableData = this.$store.state.tableDataSearch
      },
      // 搜索
      handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
      // 切换分页
      handleCurrentChange(val) {
        this.listQuery.page = val.page
        this.listQuery.limit = val.limit
        this.getList()
      },
      // 行点击
      tableRowClick(row){
        this.tableChooseRow = row
      },
      // 多行删除
      handleDelete(row) {
        this.$confirm(`确定要删除区域名称为${row.areaName}的记录吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          var data = [row.id]
          basicDataApi.qyglDelete(data).then(response => {
            if(response.code == 200){
              this.$notify({
                message: response.message,
                type: 'success',
                duration: 2000
              });
              this.getList()
              this.$basicFun.getAllList()//获取全部区域
              this.reset()//重置
            }else{
              this.$notify({
                message: response.message,
                type: 'warning',
                duration: 2000
              });
            }
          })
        }).catch(() => {});
      },
      // add弹窗取消
      cancelDialog(val){
        this.FormDialog = val
      },
      // add弹窗提交
      confirmDialog(){
         basicDataApi.qyglAdd(this.addform).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialog = false
            this.$basicFun.getAllList()//获取全部区域
            this.getList()
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.FormDialog = false
        })
        this.reset()//重置
      },
      //重置
      reset(){
        this.addform = this.modifyForm ={
          areaName:'',
          geographicalPosition:'',
          detailedAddress:'',
          isMountDevice:'',
          isMountMap:'',
          surfaceMountNum:'',
          fatherNode:'',
          project:'',
          projectCode:'',
          areaPersonNum:'',
          jurisdictionUser:'',
        }
        this.tableChooseRow = {}
      },
      // 编辑表
      modify(row){
        this.modifyForm = JSON.parse(JSON.stringify(row))
        this.FormDialogEdit = true
      },
      // 编辑取消
      cancelDialogEdit(val){
        this.FormDialogEdit = val
      },
      // 编辑提交
      confirmDialogEdit(){
        basicDataApi.qyglModify(this.modifyForm).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.FormDialogEdit = false
            this.$basicFun.getAllList()//获取全部区域
            this.getList()
            this.reset()//重置
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          // this.FormDialogEdit = false
        })
      },
      // 区域调整取消
      cancelAreaChange(val){
        this.areaChangeDialog = val
      },
      // 区域调整提交
      confirmAreaChange(){
        var ids =[]
        this.mainMultipleSelection.forEach(i=>{
          ids.push(i.id)
        })
        var data={
          fatherAreaId:this.areaChangeForm.fatherAreaId,
          ids:ids
        }
        basicDataApi.qyglAreaChange(data).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.areaChangeDialog = false
            this.$basicFun.getAllList()//获取全部区域
            this.getList()
            this.reset()//重置
            this.areaChangeForm.fatherAreaId = ''
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          // this.areaChangeDialog = false
        })
      },
      //表格多选
      handleSelectionChange(val){
        this.mainMultipleSelection = val
      },
      
      // 分配用户保存
      handleDistributionUser(val) {
        // 选择右侧数据逻辑
        // var areaIds = []
        // var ids = []
        // this.mainMultipleSelection.forEach(i=>{
        //   areaIds.push(i.id)
        // })
        // val.forEach(i=>{
        //   ids.push(i.id)
        // })
        // var data = {
        //   areaIds:areaIds,
        //   ids:ids
        // }
        var ids = []
        val.forEach(i=>{
          ids.push(i.id)
        })
        var data = {
          areaIds:this.listQuery.TreeIds,
          ids:ids
        }
        basicDataApi.qyglUserDistribution(data).then(response => {
          if(response.code == 200){
            this.$notify({
              message: response.message,
              type: 'success',
              duration: 2000
            });
            this.getList()
            this.userDialog = false
          }else{
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          // this.areaChangeDialog = false
        })
      },
      //分配用户取消
      cancelUserDialog(val){
        this.userDialog = val
      },
    },
  }

</script>

<style lang="scss" scoped>
  
</style>
